import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';
import moment from 'moment';

const state = () => ({
    dominio: null,
    codigoGenerado: null,
    tokenActivo: false,
    fechaVencimientoCodigoGenerado: null,
});
const mutations = make.mutations(state);

const getters = {};

const actions = {
    generarCodigo( {  commit }, id) {
        axios.post(`/facturacion/empresa/${id}/generate_codigo`)
        .then( response => {
            let codigoIntegracion = response.data.data.codigo_integracion;
            const EXPIRED_CODIGO = moment(codigoIntegracion.expires_at).format("DD/MM/YYYY");
            commit('SET_CODIGO_GENERADO', codigoIntegracion.token_emisor);
            commit('SET_DOMINIO', codigoIntegracion.domain_front);
            commit('SET_FECHA_VENCIMIENTO_CODIGO_GENERADO', EXPIRED_CODIGO);
            util.showNotify(response.data.message, 'success');
        }).catch( error => {
            util.showNotify(error.response.data.message, 'error');

        } )
    },
    resetDataCodigoIntegracion({commit}) {
        commit('SET_CODIGO_GENERADO', null);
        commit('SET_DOMINIO', null);
        commit('SET_TOKEN_ACTIVO', false);
        commit('SET_FECHA_VENCIMIENTO_CODIGO_GENERADO', null);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}